import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';

import ImageGallery from 'react-image-gallery';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProductDetailAsync } from '../../redux/productSlice';
import ProductCard from '../../components/ProductCard';

import ItemSkeleton from '../../components/Skeletons/ItemSkeleton';

import LoaderComponent from '../../components/LoaderComponent';
import {
  addToCart,
  resetCart,
  addToQuickBuyLoading,
  addToQuickBuySuccess,
  resetCartAdd,
} from '../../redux/cartSlice';
import Counter from '../../components/Counter';

function Index() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const params = useParams();
  const product = useSelector((state) => state.products.product);
  const loading = useSelector((state) => state.products.loading);
  const cartAdding = useSelector((state) => state.cart.cartAddSuccess);
  const [maxCount, setMaxCount] = useState(0);
  const [qty, setQty] = useState(1);

  const [outOfStock, setOutOfStock] = useState(false);
  const [orderLimit, setOrderLimit] = useState(false);

  useEffect(() => {
    product &&
      setMaxCount(
        product.stock > product.orderLimit ? product.orderLimit : product.stock
      );
  }, [product]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [params]);

  useEffect(() => {
    dispatch(getProductDetailAsync(params.slug));
    setQty(1);
  }, [dispatch, params]);

  useEffect(() => {
    if (product) {
      if (product.orderLimit <= product.stock) {
        if (qty === product.orderLimit) {
          setOrderLimit(true);
        } else {
          setOrderLimit(false);
        }
      } else {
        if (qty === product.stock) {
          setOutOfStock(true);
        } else {
          setOutOfStock(false);
        }
      }
    }
  }, [qty, product]);

  if (loading) return <ItemSkeleton />;

  return (
    <Fragment>
      {product && (
        <Container>
          <section className=' productWrapper mt-3'>
            <Row>
              <Col lg={5}>
                <section className='imageHolder'>
                  {product?.gallery.length > 0 ? (
                    <ImageGallery
                      items={product.gallery.map((g) => ({
                        original: `${process.env.REACT_APP_IMAGE_PREFIX}${g}`,
                        thumbnail: `${process.env.REACT_APP_IMAGE_PREFIX}${g}`,
                      }))}
                      showPlayButton={false}
                      thumbnailPosition='bottom'
                    />
                  ) : (
                    <img
                      className='singleImageProduct'
                      src={`${process.env.REACT_APP_IMAGE_PREFIX}/${product.heroImage}`}
                      alt={product.name}
                    />
                  )}
                </section>
              </Col>
              <Col lg={7}>
                <section className='DetailsHolder'>
                  <section className='DetailsHolder-header '>
                    <h1 className='DetailsHolder-header-title'>
                      {product.name}
                    </h1>
                    <div className='DetailsHolder-header-brand mb-3'>
                      <span className='brandKey'>Category: </span>{' '}
                      <span className='brandValue'>
                        {' '}
                        <strong>{product.category.name}</strong>{' '}
                      </span>
                    </div>
                  </section>

                  <Row>
                    <Col>
                      <section className='DetailsHolder-stock mt-3'>
                        <span className='DetailsHolder-stock-price'>
                          Rs. {product.sellingPrice}
                        </span>
                        {product.discount > 0 && (
                          <>
                            <span className='DetailsHolder-stock-cutprice ml-3'>
                              Rs. {product.price}
                            </span>
                            <span className='DetailsHolder-stock-discount ml-3'>
                              {product.discount}% off
                            </span>
                          </>
                        )}
                        <div className='DetailsHolder-stock-vatInfo'>
                          incl. of all tax
                        </div>
                        <div
                          className={`${
                            product.stock > 0
                              ? 'DetailsHolder-stock-stockInfo mt-1'
                              : 'cda mt-1'
                          }`}
                        >
                          {product.stock > 0 ? 'IN STOCK' : 'OUT OF STOCK'}
                        </div>
                      </section>
                      {product.stock > 0 && (
                        <>
                          <section className='DetailsHolder-qty mt-3'>
                            <div className='DetailsHolder-qty-name'>
                              Quantity
                            </div>
                            {orderLimit && <span>Order limit reached</span>}
                            {outOfStock && <span>Stock limit reached</span>}
                            <div className='counterHolder'>
                              <div
                                className='counterHolder-item'
                                onClick={() => {
                                  if (Number(qty) > 1) {
                                    setQty(qty - 1);
                                  }
                                }}
                              >
                                -
                              </div>
                              <div className='counterHolder-item'>{qty}</div>
                              <div
                                className='counterHolder-item'
                                onClick={() => {
                                  if (
                                    qty < product.stock &&
                                    qty < product.orderLimit
                                  ) {
                                    setQty(qty + 1);
                                  }
                                }}
                              >
                                +
                              </div>
                            </div>
                            {/* <section className='webQuantity'>
                              <Form.Group className='DetailsHolder-qty-select '>
                                <Form.Control
                                  type='number'
                                  onWheel={(e) => e.target.blur()}
                                  onChange={(e) => {
                                    if (
                                      Number(e.target.value) === product.stock
                                    ) {
                                      setOutOfStock(true);
                                    } else {
                                      setOutOfStock(false);
                                    }
                                    Number(e.target.value) <= product.stock &&
                                      Number(e.target.value) <=
                                        product.orderLimit &&
                                      setQty(Number(e.target.value));
                                  }}
                                  value={qty}
                                  min='1'
                                  max={
                                    Number(product.orderLimit) >=
                                    Number(product.stock)
                                      ? Number(product.orderLimit)
                                      : Number(product.stock)
                                  }
                                />
                              </Form.Group>
                            </section> */}

                            {/* <section className='mobQuantity'>
                              <Form.Group className='DetailsHolder-qty-select '>
                                <Form.Control
                                  type='number'
                                  onChange={(e) =>
                                    Number(e.target.value) <= product.stock &&
                                    Number(e.target.value) <=
                                      product.orderLimit &&
                                    setQty(Number(e.target.value))
                                  }
                                  value={qty}
                                  min='1'
                                  // onKeyDown={(e) => {
                                  //   e.preventDefault();
                                  //   return false;
                                  // }}
                                  // max={Number(product.orderLimit)}
                                />
                              </Form.Group>
                            </section> */}
                          </section>
                          <section className='DetailsHolder-cta mt-4 pb-4'>
                            {/* <Link to='/cart'> */}

                            <button
                              disabled={product.stock < 1}
                              className='bton bton--md bton--primary prodbuttons'
                              onClick={() => {
                                if (qty > 0) {
                                  dispatch(
                                    addToCart({
                                      product: {
                                        _id: product._id,
                                        name: product.name,
                                        slug: product.slug,
                                        stock: product.stock,
                                        orderLimit: product.orderLimit,
                                        heroImage: product.heroImage,
                                        price: product.price,
                                        discount: product.discount,
                                        sellingPrice: product.sellingPrice,
                                      },
                                      qty,
                                    })
                                  );
                                  setTimeout(() => {
                                    dispatch(resetCartAdd());
                                  }, 1000);
                                }
                              }}
                            >
                              <i class='fa  fa-shopping-cart'></i> Add to cart
                            </button>

                            {/* </Link> */}
                            {/* <button
                              className='bton bton--md bton--sec ms-0 ms-md-2 mt-2 mt-md-0 prodbuttons'
                              onClick={() => {
                                if (qty > 0) {
                                  dispatch(addToQuickBuyLoading());
                                  dispatch(
                                    addToQuickBuySuccess({
                                      product: {
                                        _id: product._id,
                                        name: product.name,
                                        slug: product.slug,
                                        stock: product.stock,
                                        orderLimit: product.orderLimit,
                                        heroImage: product.heroImage,
                                        price: product.price,
                                        discount: product.discount,
                                        sellingPrice: product.sellingPrice,
                                      },
                                      qty,
                                    })
                                  );
                                  setTimeout(() => {
                                    dispatch(resetCartAdd());
                                  }, 1000);
                                  navigate('/cart/buy-now');
                                }
                              }}
                            >
                              <i class='fa fa-rocket'></i> Buy Now
                            </button> */}
                            {cartAdding && (
                              <div className='csu mt-3 addedSuccess'>
                                Product Added Successfully !!
                              </div>
                            )}
                          </section>
                        </>
                      )}
                    </Col>

                    {/* <Col>
                      <section className='DetailsHolder-highlights '>
                        <h2>Product Hilights</h2>
                        <ul>
                          <li>6 month Installment available.</li>
                          <li>6 month Installment available.</li>
                          <li>6 month Installment available.</li>
                          <li>6 month Installment available.</li>
                        </ul>
                      </section>
                    </Col> */}
                  </Row>
                </section>
              </Col>
            </Row>
          </section>

          <Container style={{ marginTop: '3rem' }}>
            <Row>
              <Col lg={6}>
                <div className=' productTechInfo'>
                  <section className='productTechDesc '>
                    <h2 className='mt-3'>Technical Specs</h2>
                    <ul className='mt-3'>
                      {product.information &&
                        product.information.map((info) => {
                          return (
                            <li className='productTechDesc-box mt-2 '>
                              <div className='productTechDesc-box-key '>
                                {info.informationKey}
                              </div>{' '}
                              <div className='productTechDesc-box '>
                                {info.informationValue}
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </section>
                </div>
              </Col>
              <Col lg={6}>
                <div className=' productInfoWrapper'>
                  <section className='productDesc '>
                    <h2 className='mt-3'>Description</h2>
                    <p
                      className='mt-3'
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  </section>
                </div>
              </Col>
            </Row>
            {product.related.length > 0 && (
              <>
                <h1 className='text-center m-5'>Related Products</h1>

                <Row>
                  {product.related.map((rp) => (
                    <ProductCard column='3' fProduct={rp} />
                  ))}
                </Row>
              </>
            )}
          </Container>
        </Container>
      )}
    </Fragment>
  );
}

export default Index;
