import React, { useEffect } from 'react';
import Ads from '../../components/Ads';
import BestSelling from '../../components/BestSelling';
import CarouselBox from '../../components/CarouselBox';
import LatestProducts from '../../components/LatestProducts';
import About from '../About';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <CarouselBox />
      <BestSelling />
      <LatestProducts />
      <Ads />
      <About />
    </>
  );
};

export default Home;
