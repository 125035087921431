import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getProductsAsync = createAsyncThunk(
  'product/getProductsAsync',
  async (keyword) => {
    const response = await axios.get(
      `/api/products/website?keyword=${keyword}`
    );

    if (response.status === 200) {
      const products = response.data.products;
      return { products };
    }
  }
);

export const getProductDetailAsync = createAsyncThunk(
  'product/getProductDetailAsync',
  async (slug) => {
    const response = await axios.get(`/api/products/${slug}/slug`);
    if (response.status === 200) {
      const product = response.data;
      return { product };
    }
  }
);

export const getProductByCategoryAsync = createAsyncThunk(
  'product/getProductByCategoryAsync',
  async (data) => {
    const response = await axios.post(
      `/api/products/category/${data.id}`,
      data
    );
    if (response.status === 200) {
      const products = response.data.products;
      const count = response.data.count;
      return { products, count };
    }
  }
);

// export const filterProductsAsync = createAsyncThunk(
//   'product/filterProductsAsync',
//   async (data, state) => {
//     return { data };
//   }
// );

const productSlice = createSlice({
  name: 'product',
  initialState: {
    products: [],
    totalProducts: 0,
    product: null,
    loading: false,
    filtering: false,
  },
  reducers: {
    filteringStart: (state) => {
      state.filtering = true;
    },
    filteringEnd: (state) => {
      state.filtering = false;
    },
  },
  extraReducers: {
    [getProductDetailAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getProductDetailAsync.fulfilled]: (state, action) => {
      state.product = action.payload.product;
      state.loading = false;
    },
    [getProductByCategoryAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getProductByCategoryAsync.fulfilled]: (state, action) => {
      state.products = action.payload.products;
      state.loading = false;
      state.filtering = false;
      state.totalProducts = action.payload.count;
    },
    [getProductsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getProductsAsync.fulfilled]: (state, action) => {
      state.products = action.payload.products;
      state.loading = false;
    },
  },
});

export const { filteringStart, filteringEnd } = productSlice.actions;

export default productSlice.reducer;
