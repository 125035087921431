import moment from 'moment/moment';
import React, { useEffect } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ProductImage from '../../assets/images/productImage.png';
import { getSubscriberOrdersAsync } from '../../redux/subscriberSlice';

const MyOrders = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.subscribers);
  useEffect(() => {
    dispatch(getSubscriberOrdersAsync());
  }, []);
  return (
    <div>
      <Container>
        <section>
          <h1>My Orders</h1>
        </section>

        {orders &&
          orders.map((order) => (
            <section className='orderWrapper'>
              <section key={order._id} className='flex-between  p-2'>
                <div>{moment(order.createdAt).format('lll')}</div>
                <div>
                  <strong>Status : </strong>{' '}
                  {order.deliveryStatus.replaceAll('_', ' ')}{' '}
                </div>
              </section>
              <Table>
                <thead className='myOrderTable'>
                  <tr>
                    <th>S.N</th>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Sub-Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.orderItems.map((item, index) => (
                    <tr key={item._id}>
                      <td className='myOrderTable'>{index + 1}</td>
                      <td>
                        <div className='imageHolder'>
                          <figure>
                            <img
                              src={`${process.env.REACT_APP_IMAGE_PREFIX}/${item.productImage}`}
                              alt=''
                            />
                          </figure>
                          <div className='imageHolder-text'>
                            {item.productName}
                          </div>{' '}
                        </div>
                      </td>
                      <td>{item.quantity}</td>
                      <td className='myOrderTable'>
                        ${item.productPrice.toFixed(2)}
                      </td>
                      <td>
                        ${(item.quantity * item.productPrice).toFixed(2)}{' '}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className='text-end me-3'>
                <span>
                  <strong>Total: ${order.grandTotal.toFixed(2)}</strong>
                </span>
                <div>with Discount</div>
              </div>
            </section>
          ))}
      </Container>
    </div>
  );
};

export default MyOrders;
