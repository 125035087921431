import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  addEmailOrderAsync,
  addOrderAsync,
  addWhatsAppOrderAsync,
  removeCart,
  resetCart,
} from '../../redux/cartSlice';

function Index({ show, errors, setErrors }) {
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(null);
  const [success, setSuccess] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [whatsAppNumber, setWhatsAppNumber] = useState(null);
  const [whatsAppNumberEmail, setWhatsAppNumberEmail] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [type, setType] = useState(null);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    cartItems,
    buyNowCartItems,
    total,
    discount,
    grandTotal,
    buyNowTotal,
    buyNowDiscount,
    buyNowGrandTotal,
    cartAddLoading,
    isOrderPlaceSuccess,
    isOrderPlaceWhatsAppSuccess,
    isOrderPlaceEmailSuccess,
  } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.subscribers);

  const handleSubmit = async () => {
    setErrors([]);
    dispatch(
      addOrderAsync({
        order: {
          cart: !location.pathname.includes('buy-now')
            ? { cartItems, quickBuy: false }
            : {
                cartItems: buyNowCartItems,
                quickBuy: true,
              },
        },
      })
    );
    // !location.pathname.includes('buy-now')
    //               ? '/shipping'
    //               : '/shipping/buy-now'
  };
  const handleWhatsAppMessaging = async (e) => {
    setSubmitting(true);
    try {
      const { data } = await axios.post(
        'https://graph.facebook.com/v15.0/103909512605460/messages',
        {
          messaging_product: 'whatsapp',
          type: 'text',
          text: {
            preview_url: false,
            body: `Thank you for contacting us. We have received your query for these items. ${cartItems.map(
              (i) => `${i.qty} X ${i.product.name}`
            )} \nWe will reach out to you shortly.
            `,
          },
          to: '9779847059929',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer EAAMZCWAQrWlUBAGVGzKteMpxziFZBJywXwi5V9FVfbF70X8YVys4iSzQUL6mFdvVl8pdBE0ZCZBGnXOAnRNNDD9Q0seeZASKJZCiEgzbnNDUBUrJHfbGn2pvltj8GKhFMlhk2QyuDHVOlFPmkkRdLEyxPmY61jnxAJPEuIX9zE7u1VVf6fgX0VkfHLzDHUIXGLrWYxO2vCO9xrovxUjQhG`,
          },
        }
      );
      if (data) {
        dispatch(
          addWhatsAppOrderAsync({
            order: {
              cart: !location.pathname.includes('buy-now')
                ? {
                    cartItems,
                    quickBuy: false,
                    subscriberPhone: whatsAppNumber,
                  }
                : {
                    cartItems: buyNowCartItems,
                    quickBuy: true,
                  },
            },
          })
        );
        setSubmitting(false);
        setShowModal(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleEmailMessaging = async (e) => {
    setSubmitting(true);
    try {
      dispatch(
        addEmailOrderAsync({
          order: {
            cart: !location.pathname.includes('buy-now')
              ? {
                  cartItems,
                  quickBuy: false,
                  subscriberPhone: whatsAppNumberEmail,
                  subscriberEmail: userEmail,
                }
              : {
                  cartItems: buyNowCartItems,
                  quickBuy: true,
                },
          },
        })
      );
      setSubmitting(false);
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };
  const handleSuccessClose = () => {
    dispatch(removeCart());
    setShowSuccess(false);
    navigate('/');
  };
  useEffect(() => {
    dispatch(resetCart());
  }, []);

  useEffect(() => {
    if (
      isOrderPlaceEmailSuccess ||
      isOrderPlaceSuccess ||
      isOrderPlaceWhatsAppSuccess
    ) {
      setShowSuccess(true);
    }
  }, [
    isOrderPlaceEmailSuccess,
    isOrderPlaceSuccess,
    isOrderPlaceWhatsAppSuccess,
  ]);

  if (cartAddLoading) {
    return <p>Loading...</p>;
  }
  return !location.pathname.includes('buy-now') ? (
    <Fragment>
      <Modal show={showSuccess} onHide={handleSuccessClose}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className='csu'>Woohoo, Your order has been placed!</section>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            className='bton bton--primary bton--md'
            onClick={handleSuccessClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        type={type}
        show={showModal}
        onHide={handleClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Order via {type}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            {type === 'WhatsApp' && (
              <Form>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>WhatsApp Number</Form.Label>
                  <Form.Control
                    type='text'
                    value={whatsAppNumber}
                    onChange={(e) => setWhatsAppNumber(e.target.value)}
                    placeholder='Enter WhatsApp Number'
                  />
                </Form.Group>
              </Form>
            )}
            {type === 'email' && (
              <Form>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>email</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter email'
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>phone</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter phone'
                    value={whatsAppNumberEmail}
                    onChange={(e) => setWhatsAppNumberEmail(e.target.value)}
                  />
                </Form.Group>
              </Form>
            )}
          </section>
        </Modal.Body>
        <Modal.Footer>
          {type === 'WhatsApp' ? (
            <button
              className='bton bton--primary bton--sm'
              onClick={handleWhatsAppMessaging}
            >
              submit
            </button>
          ) : (
            <button
              className='bton bton--primary bton--sm'
              onClick={handleEmailMessaging}
            >
              submit
            </button>
          )}
          <button
            className='bton bton--ghost--danger bton--sm'
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <section className='billDetails'>
        {' '}
        <h2 className=''>Billing Details</h2>
        <section className='calc mt-3'>
          <div className='calc-holder '>
            <div className='calc-holder-detail'>Total M.R.P</div>
            <div className='calc-holder-number'>Rs. {total}</div>
          </div>
          <div className='calc-holder '>
            <div className='calc-holder-detail'>Shipping cost</div>
            <div className='calc-holder-number'>calculated later</div>
          </div>
          <div className='calc-holder'>
            <div className='calc-holder-detail'>Discount</div>
            <div className='calc-holder-number'>Rs. {discount}</div>
          </div>
        </section>
        <div className='calc-holder mt-2'>
          <div className='calc-holder-detail'>
            {' '}
            <strong>GRAND-TOTAL</strong>
          </div>
          <div className='calc-holder-number '>
            <strong> Rs. {grandTotal}</strong>{' '}
          </div>
        </div>
        {show ? (
          <section className='mt-3'>
            {user ? (
              <button
                onClick={handleSubmit}
                // onClick={}
                className='bton bton--full bton--primary'
              >
                Checkout
              </button>
            ) : (
              <Link to='/login' state={{ cartPage: true }}>
                <button
                  // onClick={handleSubmit}
                  className='bton bton--full bton--primary'
                >
                  Login to Checkout
                </button>
              </Link>
            )}

            <section className='guestCheckout'>OR Guest Checkout via</section>
            <div
              className='buttons-wa-email'
              style={{ display: 'flex', marginTop: '10px', gap: '2px' }}
            >
              <button
                style={{ background: '#25D366', border: 'none' }}
                onClick={() => {
                  setType('WhatsApp');
                  handleShow();
                }}
                className='bton bton--full bton--primary'
              >
                <i class='fa fa-whatsapp'></i> WhatsApp
              </button>
              <button
                onClick={() => {
                  setType('email');
                  handleShow();
                }}
                className='bton bton--full bton--danger'
              >
                <i class='fa fa-envelope'></i> Email
              </button>
            </div>
          </section>
        ) : null}
      </section>
    </Fragment>
  ) : (
    <Fragment>
      <section className='billDetails'>
        {' '}
        <h2 className=''>Billing Details</h2>
        <section className='calc mt-3'>
          <div className='calc-holder '>
            <div className='calc-holder-detail'>Total M.R.P</div>
            <div className='calc-holder-number'>Rs. {buyNowTotal}</div>
          </div>
          <div className='calc-holder '>
            <div className='calc-holder-detail'>Shipping cost</div>
            <div className='calc-holder-number'>calculated later</div>
          </div>
          <div className='calc-holder'>
            <div className='calc-holder-detail'>Discount</div>
            <div className='calc-holder-number'>Rs. {buyNowDiscount}</div>
          </div>
        </section>
        <div className='calc-holder mt-2'>
          <div className='calc-holder-detail'>
            {' '}
            <strong>GRAND-TOTAL</strong>
          </div>
          <div className='calc-holder-number '>
            <strong> Rs. {buyNowGrandTotal}</strong>{' '}
          </div>
        </div>
        {show ? (
          <section className='mt-3'>
            <button
              onClick={handleSubmit}
              className='bton bton--full bton--primary'
            >
              Checkout
            </button>
          </section>
        ) : null}
      </section>
    </Fragment>
  );
}

export default Index;
