import React from 'react';

const LoaderComponent = ({ height }) => {
  return (
    <ul class='loader' style={{ height: `${height}rem` }}>
      <li class='loader__item'></li>
      <li class='loader__item'></li>
      <li class='loader__item'></li>
    </ul>
  );
};

export default LoaderComponent;
