import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Col, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { addToCart, resetCart } from '../../redux/cartSlice';

function ProductCard({ column, fProduct }) {
  const dispatch = useDispatch();
  return (
    <>
      {fProduct && (
        <Col lg={column} md={6}>
          <Link to={`/product/${fProduct.slug}`}>
            <section className='productCard'>
              <figure className='productCard-image'>
                <img
                  src={`${process.env.REACT_APP_IMAGE_PREFIX}${fProduct.heroImage}`}
                  title={fProduct.name}
                />
              </figure>
              <h3 className='productCard-title' title={fProduct.name}>
                {fProduct.name}
              </h3>
              <div className='productCard-price'>
                <span className='priceValue'>Rs {fProduct.sellingPrice} </span>{' '}
                <span
                  className={`${fProduct.stock > 0 ? 'stockValue' : 'cda'}`}
                >
                  {fProduct.stock > 0 ? 'IN STOCK' : 'OUT OF STOCK'}
                </span>
              </div>
              {fProduct.discount > 0 && (
                <span className='cardOff'>{fProduct.discount}%off</span>
              )}
            </section>
          </Link>
        </Col>
      )}
    </>
  );
}

export default ProductCard;
